.featured {
    flex: 2;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;


    .top {
        padding: 20px;
        border: 1px solid #c9c9c9;
        border-radius: 4px;
        background-color: #0052cc;
        color: #fff;

        p{
            margin-bottom: 20px;
        }
        .attendence-col{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content:space-between;
            .attendence-col-1{
                p{
                    margin-bottom: 5px;
                }
                h4{
                    font-size: 30px;
                    letter-spacing: 2;
                    span{
                        font-size: 16px;
                    }
                }
            }
            .attendence-col-2{
                .btn-clock-in{
                    background-color: #fff;
                    border: 1px solid #fff;
                    color: #0052cc;
                    border-radius: 4px;
                    padding: 10px;
                    cursor: pointer;
                    
                }
                .btn-clock-out{
                    background-color: rgb(200, 8, 8);
                    border: 1px solid rgb(200, 8, 8);
                    color: #fff;
                    border-radius: 4px;
                    padding: 10px;
                    cursor: pointer;
                }
                .btn-clock-break{
                    background-color: rgb(104 90 72 / 84%);
                    border: 1px solid rgb(104 90 72 / 84%);
                    color: #fff;
                    border-radius: 4px;
                    padding: 10px;
                    cursor: pointer;
                }
                .total-time{
                    display: block;
                    margin-top: 8px;
                }
            }
        }
    }

    // .title {
    //     font-size: 16px;
    //     color: #42526e;
    //     font-weight: bold;
    // }

    // .bottom {
    //     padding: 20px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     gap: 15px;

    //     .featuredChart {
    //         width: 100px;
    //         height: 100px;
    //     }

    //     .title {
    //         font-weight: 500;
    //         color: #42526e;
    //     }

    //     .amount {
    //         font-size: 30px;
    //         color: #42526e;
    //         font-weight: bold;
    //     }

    //     .desc {
    //         font-weight: 300;
    //         font-size: 12px;
    //         color: #42526e;
    //         text-align: center;
    //     }

    //     .summary {
    //         width: 100%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;

    //         .item {
    //             text-align: center;

    //             .itemTitle {
    //                 font-size: 14px;
    //                 color: #42526e;
    //             }

    //             .itemResult {
    //                 display: flex;
    //                 align-items: center;
    //                 margin-top: 10px;
    //                 font-size: 14px;

    //                 &.positive {
    //                     color: green;
    //                 }

    //                 &.negative {
    //                     color: red;
    //                 }
    //             }
    //         }
    //     }
    // }
}