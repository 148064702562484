.phone-calling-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    flex-wrap: wrap;

    .phone-calling-colum-1 {
        flex-basis: 30%;

        .user-name-and-number-row {
            text-align: center;

            .user-short-name {
                span {
                    display: block;
                    text-align: center;
                    color: #0052cc;

                    svg {
                        font-size: 40px;
                    }

                }
            }

            .user-name-number {
                padding-left: 10px;

                h6 {
                    font-size: 16px;
                    color: #42526e;
                }

                p {
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
    }

    .phone-calling-colum-2 {
        p.oncall {
            text-align: center;
            color: rgb(4, 92, 4);
        }

        p.ringing {
            text-align: center;
            color: #0052cc;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 10px;
            }
        }

        flex-basis: 30%;

        img {
            width: 100%;
            height: 48px;
        }
    }

    .phone-calling-colum-3 {
        flex-basis: 30%;

        .user-name-and-number-row {
            text-align: center;

            .user-short-name {
                span {
                    display: block;
                    text-align: center;
                    color: #0052cc;

                    svg {
                        font-size: 40px;
                    }

                }
            }

            .user-name-number {
                padding-left: 10px;

                h6 {
                    font-size: 16px;
                    color: #42526e;
                }

                p {
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
    }

    .phone-calling-colum-4 {
        flex-basis: 100%;
        border-top: 1px solid #ddd;
        text-align: center;
        padding-top: 10px;
        margin-top: 10px;

        .btn-call-end {
            background-color: rgb(148, 11, 11);
            color: #fff;
            border: 1px solid rgb(148, 11, 11);
            padding: 6px 26px;
            font-size: 18px;
            font-weight: 600;
            border-radius: 50px;
        }
    }

    .call-disconnect-btn {
        background-color: #c01f1f;
        border: 1px solid #c01f1f;
        width: 100%;
        color: white;
        border-radius: 10px;
        padding: 8px 10px;
        margin: 4px;
        cursor: pointer;
    }

    .call-disconnect-btn:disabled {
        background-color: #c01f1f;
        border: 1px solid #c01f1f;
        cursor: not-allowed;
    }
}