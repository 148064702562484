//css for list
.zoomCallListing {
    display: flex;
    // width: 100%;

    .zoomCallListingContainer {
        flex: 6;

        .loader {
            display: flex;
            margin-top: 26px;
            padding: 20px;
            flex-direction: row;
            justify-content: center;
        }

        .datatableTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            width: 97%;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 6px 10px 4px 10px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
            }
        }
    }

}