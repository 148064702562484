/*======================
    404 page
=======================*/


.page_404 {
    padding: 60px 0;
    background: #fff;
    text-align: center;

    img {
        width: 100%;
    }

    .four_zero_four_bg {

        background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;

        h1 {
            font-size: 80px;
            span{
                color: red;
            }
        }


    }

    .contant_box_404 {
        h3 {
            font-size: 40px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            font-size: 18px;
        }

        .link_404 {
            width: 150px;
            padding: 10px 20px;
            display: block;
            text-align: center;
            border: none;
            background-color: #0052cc;
            color: white;
            border-radius: 4px;
            font-weight: bold;
            cursor: pointer;
            margin-top: 30px;
            display: inline-block;
            text-decoration: none;
        }

    }
}